import React, { useEffect, useState } from 'react';
import { getAllJobs, setupPeriodicRefresh, cancelPeriodicRefresh } from './jobHistoryManager';
import JobHistoryDetailsCard from './JobHistoryDetailsCard';
import JobHistoryDetailsComponent from './JobHistoryDetailsComponent';
import jamieIcon from '../../media/images/services/jamie-logo-final.png';
import { FileX, Clock } from 'lucide-react';
import { useActiveTab } from '../ActiveTabContext';

const serviceIcons = {
  Jamie: jamieIcon,
  // Add other services here as they become available
};

const JobHistoryComponent = ({ onJobSelect }) => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const { goBack } = useActiveTab(); 

  const loadJobs = () => {
    try {
      const jobHistory = getAllJobs();
      console.log('Job History:', jobHistory);
      
      const sortedJobs = jobHistory.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      
      setJobs(sortedJobs);

      // Check for jobId in URL params
      const urlParams = new URLSearchParams(window.location.search);
      const jobId = urlParams.get('jobId');
      if (jobId) {
        const job = sortedJobs.find(j => j.jobId === jobId);
        if (job) {
          setSelectedJob(job);
        } else {
          console.warn('Job not found for ID:', jobId);
        }
      }
    } catch (err) {
      console.error('Error fetching job history:', err);
      setError('Failed to load job history');
    }
  };

  useEffect(() => {
    loadJobs();

    const handleRefresh = setupPeriodicRefresh(() => {
      loadJobs();
    }, 50);

    return () => {
      handleRefresh();
    };
  }, []);

  const getServiceIcon = (serviceName) => {
    return serviceIcons[serviceName] || jamieIcon;
  };

  const copyToClipboard = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text).then(() => {
      setCopiedId(text);
      setTimeout(() => setCopiedId(null), 2000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleJobSelect = (jobId) => {
    const job = jobs.find(j => j.jobId === jobId);
    setSelectedJob(job);
    if (onJobSelect) onJobSelect(jobId);

    // Update URL with jobId
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('jobId', jobId);
    window.history.pushState({}, '', newUrl);
  };

  const handleBackToList = () => {
    setSelectedJob(null);
    setTimeout(() => {
      loadJobs();
    }, 1000); // 1 second delay
    // Remove jobId from URL
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete('jobId');
    window.history.pushState({}, '', newUrl);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (selectedJob) {
    cancelPeriodicRefresh();
    return (
      <JobHistoryDetailsComponent 
        jobId={selectedJob.jobId} 
        onBack={handleBackToList}
        // Add other necessary props here
      />
    );
  }

  return (
    <div className="job-history-container bg-black text-white p-6">
      <div className="flex flex-col items-center mb-2 mt-36">
        <div className="w-full flex justify-between items-center" style={{ maxWidth: '70vw' }}>
          <button onClick={goBack} className="back-button text-white font-semibold hover:text-orange-600">
            ← Back
          </button>
          <h1 className="text-3xl font-bold flex items-center">
            <Clock className="mr-2" size={24} /> Job History
          </h1>
          <div className="w-20"></div>
        </div>
      </div>
      <h3 className="text-xl mb-6 text-center">Browse Your Previous CASCDR Job Results & Status</h3>
      {jobs.length === 0 ? (
        <div className="flex flex-col items-center justify-center mt-20">
          <FileX size={64} className="text-gray-500 mb-4" />
          <p className="text-xl text-gray-500">No Previous Job History Detected!</p>
          <p className="text-gray-400 mt-2">Your completed jobs will appear here</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-auto" style={{ maxWidth: '70vw' }}>
          {jobs.map((job) => (
            <JobHistoryDetailsCard
              key={job.jobId}
              job={job}
              onJobSelect={handleJobSelect}
              copiedId={copiedId}
              onCopy={copyToClipboard}
              getServiceIcon={getServiceIcon}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default JobHistoryComponent;