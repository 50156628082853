import axios from 'axios';
const debug = false
const authBaseURL = (debug === false) ? "https://cascdr-auth-backend-cw4nk.ondigitalocean.app" : "http://localhost:4020"

async function checkEligibility() {
    const checkEligibilityEndpoint = `${authBaseURL}/check-my-eligibility`;
    console.log(`bc:config:${localStorage.getItem('bc:config') !== null}`);
    const hasBCConfig = localStorage.getItem('bc:config') !== null;
    if(hasBCConfig){
        return true;
    }
    const email = localStorage.getItem('email');
    try {
        const response = await axios.post(checkEligibilityEndpoint, { email });
        return response.data.eligible;
    } catch (error) {
        console.error('Error checking eligibility', error.response ? error.response.data : error);
        return false;
    }
}

async function signIn(email, password) {
    console.log("cascdr sign in");
    const signInEndpoint = `${authBaseURL}/signin`;

    try {
        // 1. Call API
        const response = await axios.post(signInEndpoint, { email, password });

        // 2. Set variables
        // Assuming you're storing these in localStorage for this example
        // a. Email
        localStorage.setItem('email', email);

        // b. jwt
        localStorage.setItem('cascdrAuthJwt', response.data.token);

        const subscribedStatus = (response.data.subscriptionValid === true) ? "true" : "false"
        localStorage.setItem('isSubscribed', subscribedStatus);
        console.log('Sign in successful', response.data.message);
        return response.data; // Returning the data might be useful for the caller
    } catch (error) {
        console.error('Sign in failed', error.response ? error.response.data : error);
        throw error; // Rethrow to allow the caller to handle the error
    }
}

async function signUp(email, password) {
    const signUpEndpoint = `${authBaseURL}/signup`;

    try {
        // Similar to signIn, we call the API
        const response = await axios.post(signUpEndpoint, { email, password });

        // After successful signup, you might want to automatically sign the user in,
        // or you might just inform them that the signup was successful
        console.log('Sign up successful', response.data);
        localStorage.setItem('email', email);
        localStorage.setItem('cascdrAuthJwt', response.data.token);
        return response.data; // Returning the data might be useful for the caller
    } catch (error) {
        console.error('Sign up failed', error.response ? error.response.data : error);
        throw error; // Rethrow to allow the caller to handle the error
    }
}

async function signOut(){
    //Remove all stored variables

    //a. Email
    localStorage.removeItem('email')
    //b. jwt
    localStorage.removeItem('cascdrAuthJwt')
    localStorage.removeItem('isSubscribed')
}

async function refreshSubAndSignInStatus() {
    const jwt = localStorage.getItem('cascdrAuthJwt'); // 1. Pull jwt
    if (!jwt) return { signedIn: false, subscriptionValid: false };
  
    try {
      const response = await axios.get(`${authBaseURL}/validate-subscription`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      // 2. Check authenticated user's sub & validity
      return response.data; // Contains signedIn and subscriptionValid
    } catch (error) {
      console.error('Error refreshing subscription and sign-in status:', error);
      return { signedIn: false, subscriptionValid: false };
    }
  }

export {
    signIn,
    signUp,
    checkEligibility,
    signOut,
    refreshSubAndSignInStatus
  };