// jobHistoryManager.js
import axios from 'axios';
import { run_rss_agent } from '../nip105-client';

const JOB_HISTORY_KEY = 'jobHistory';
const DEBUG = false;
const apiBaseURL = DEBUG ? "http://localhost:4020" : "https://cascdr-auth-backend-cw4nk.ondigitalocean.app";
const transcriptFetchURL = "https://whispr-v3-w-caching-ex8zk.ondigitalocean.app";
const scanStatusEndpoint = "/scan-job-statuses";
const REFRESH_INTERVAL = 20000; // 20 seconds
let refreshIntervalId = null;
let jobSubmissionStatus = {};

// Enum for job status
const JobStatus = {
    WORKING: 'WORKING',
    DONE: 'DONE',
    ERROR: 'ERROR',
    AWAITING_PARENT_JOB: 'AWAITING PARENT JOB'
};

const scanJobStatuses = async (limit = 50, skipJobSubmission = false) => {
    try {
        let allJobs = getAllJobs();
        let jobsToCheck = allJobs.filter(job => 
        (job.jobId) &&
            (job.status === JobStatus.WORKING && !job.jobId.startsWith("T-")) ||
            (job.status === JobStatus.AWAITING_PARENT_JOB && job.jobId.startsWith("T-"))
        );

        jobsToCheck.sort((a, b) => new Date(b.date) - new Date(a.date));
        jobsToCheck = jobsToCheck.slice(0, limit);

        const jobIds = jobsToCheck.map(job => job.jobId.startsWith("T-") ? job.jobId.slice(2) : job.jobId);

        if (jobIds.length === 0) {
            console.log('No jobs to scan');
            return [];
        }

        const response = await axios.post(`${apiBaseURL}${scanStatusEndpoint}`, {
            jobIds: jobIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (response.data && response.data.jobStatuses) {
            for (const updatedJob of response.data.jobStatuses) {
                try {
                    await updateJobStatus(updatedJob.paymentHash, updatedJob.state);
                } catch (error) {
                    console.error(`Error updating job ${updatedJob.paymentHash}:`, error);
                }
            }
        }

        if(skipJobSubmission){
            return response.data.jobStatuses;
        }

        // Check for orphaned analysis jobs
        await checkOrphanedAnalysisJobs(jobsToCheck, response.data.jobStatuses);

        return response.data.jobStatuses;
    } catch (error) {
        console.error('Error scanning job statuses:', error);
        throw error;
    }
};

const checkOrphanedAnalysisJobs = async (jobsToCheck, updatedStatuses) => {
    const analysisJobs = jobsToCheck.filter(job => job.status === JobStatus.AWAITING_PARENT_JOB && job.jobId.startsWith("T-"));
    
    for (const analysisJob of analysisJobs) {
        const parentJobId = analysisJob.jobId.slice(2);
        const parentJobStatus = updatedStatuses.find(status => status.paymentHash === parentJobId)?.state;
        
        if (parentJobStatus === JobStatus.DONE) {
            await triggerAnalysisJob(analysisJob, parentJobId);
        }
    }
};



const setupPeriodicRefresh = (setJobStatuses, limit = 50) => {
    const refreshJobStatuses = async () => {
        try {
            const statuses = await scanJobStatuses(limit);
            setJobStatuses(statuses);
        } catch (error) {
            console.error('Failed to refresh job statuses:', error);
        }
    };

    // Initial refresh
    refreshJobStatuses();

    // Set up interval for periodic refresh
    refreshIntervalId = setInterval(refreshJobStatuses, REFRESH_INTERVAL);

    // Return a cleanup function to clear the interval when component unmounts
    return () => clearInterval(refreshIntervalId);
};

const cancelPeriodicRefresh = () => {
    if (refreshIntervalId) {
        clearInterval(refreshIntervalId);
        refreshIntervalId = null;
        console.log('Periodic refresh cancelled');
    } else {
        console.log('No active periodic refresh to cancel');
    }
};

const getJobHistory = () => {
    const jobHistory = localStorage.getItem(JOB_HISTORY_KEY);
    return jobHistory ? JSON.parse(jobHistory) : [];
};

const saveJobHistory = (jobHistory) => {
    localStorage.setItem(JOB_HISTORY_KEY, JSON.stringify(jobHistory));
};

const addJob = (serviceName, inputField1, inputField2, jobId, status = JobStatus.WORKING, outputType = '', resultPreview = '',parentJobId = null) => {
    const jobHistory = getJobHistory();
    const newJob = { 
        serviceName, 
        inputField1, 
        inputField2, 
        jobId, 
        status, 
        date: new Date().toISOString(),
        outputType,
        resultPreview,
        parentJobId
    };
    jobHistory.push(newJob);
    saveJobHistory(jobHistory);
    return newJob;
};

const addBlockedJob = (serviceName, inputField1, inputField2, parentJobId, outputType, resultPreview) => {
    const jobId = `T-${parentJobId}`;
    return addJob(
        serviceName,
        inputField1,
        inputField2,
        jobId,
        JobStatus.AWAITING_PARENT_JOB,
        outputType,
        resultPreview,
        parentJobId
    );
};

const removeJob = (jobId) => {
    let jobHistory = getJobHistory();
    jobHistory = jobHistory.filter(job => job.jobId !== jobId);
    saveJobHistory(jobHistory);
};

const updateJobStatus = async (jobId, newStatus) => {
    console.log(`Attempting to update job ${jobId} to status: ${newStatus}`);
    
    if (!newStatus || !Object.values(JobStatus).includes(newStatus)) {
        console.error(`Invalid job status received for job ${jobId}: ${newStatus}`);
        return false;
    }
    
    let jobHistory = getJobHistory();
    const jobIndex = jobHistory.findIndex(job => job.jobId === jobId);
    console.log(`L159 jobHistory: ${JSON.stringify(jobHistory)}`);
    console.log(`L160 jobIndex: ${jobIndex}`);
    if (jobIndex !== -1) {
        const oldStatus = jobHistory[jobIndex].status;
        jobHistory[jobIndex].status = newStatus;
        // Special handling for Transcript jobs that are marked as DONE
        console.log(`newStatus === JobStatus.DONE:${newStatus === JobStatus.DONE}`)
        console.log(`jobHistory[jobIndex].inputField1: ${jobHistory[jobIndex].inputField1}`)
        console.log(`jobHistory[jobIndex].inputField1 === 'Transcript from Link' || jobHistory[jobIndex].inputField1 === 'Transcript from Upload':${jobHistory[jobIndex].inputField1 === 'Transcript from Link' || jobHistory[jobIndex].inputField1 === 'Transcript from Upload'}`);

        if (newStatus === JobStatus.DONE && (jobHistory[jobIndex].inputField1 === 'Transcript from Link' || jobHistory[jobIndex].inputField1 === 'Transcript from Upload')) {
            try {
                const transcriptData = await fetchTranscriptData(jobId);
                if (transcriptData && transcriptData.channels && transcriptData.channels[0] && 
                    transcriptData.channels[0].alternatives && transcriptData.channels[0].alternatives[0] && 
                    transcriptData.channels[0].alternatives[0].paragraphs && 
                    transcriptData.channels[0].alternatives[0].paragraphs.paragraphs) {
                    
                    const firstSentence = transcriptData.channels[0].alternatives[0].paragraphs.paragraphs[0].sentences[0].text;
                    const secondSentence = transcriptData.channels[0].alternatives[0].paragraphs.paragraphs[0].sentences[1].text;
                    jobHistory[jobIndex].resultPreview = firstSentence + ' ' + secondSentence;
                }
            } catch (error) {
                console.error(`Error fetching transcript data for job ${jobId}:`, error);
            }
        }

        saveJobHistory(jobHistory);

        console.log(`Updated job ${jobId} from ${oldStatus} to ${newStatus}`);

        // If the job has transitioned to DONE, check for blocked jobs
        if (newStatus === JobStatus.DONE) {
            await checkAndTriggerBlockedJobs(jobId);
        }

        return true;
    } else {
        console.warn(`Job ${jobId} not found in history`);
        return false;
    }
};

const updateJob = (jobId, updates) => {
    let jobHistory = getJobHistory();
    const jobIndex = jobHistory.findIndex(job => job.jobId === jobId);
    
    if (jobIndex !== -1) {
        jobHistory[jobIndex] = { ...jobHistory[jobIndex], ...updates };
        saveJobHistory(jobHistory);
        return jobHistory[jobIndex];
    }
    return null;
};

const getAllJobs = () => {
    const jobHistory = localStorage.getItem(JOB_HISTORY_KEY);
    if (!jobHistory) return [];
    
    try {
        const parsedJobs = JSON.parse(jobHistory);
        return Array.isArray(parsedJobs) ? parsedJobs : [];
    } catch (error) {
        console.error('Error parsing job history:', error);
        return [];
    }
};

const getJob = (jobId) => {
    const jobHistory = getJobHistory();
    return jobHistory.find(job => job.jobId === jobId);
};

const getJobsByParentJobId = (parentJobId) => {
    const jobHistory = getJobHistory();
    return jobHistory.filter(job => job.parentJobId === parentJobId);
};

const fetchTranscriptData = async (jobId) => {
    try {
        const response = await axios.get(`${transcriptFetchURL}/WHSPR/${jobId}/get_result`);
        return response.data;
    } catch (error) {
        console.error('Error fetching transcript data:', error);
        throw error;
    }
};

const checkAndTriggerBlockedJobs = async (parentJobId) => {
    console.log(`Checking for blocked jobs related to ${parentJobId}`);
    const jobHistory = getJobHistory();
    const blockedJobs = jobHistory.filter(job => 
        job.status === JobStatus.AWAITING_PARENT_JOB && job.jobId === `T-${parentJobId}`
    );

    console.log(`Found ${blockedJobs.length} blocked jobs for ${parentJobId}`);

    for (const blockedJob of blockedJobs) {
        await triggerAnalysisJob(blockedJob, parentJobId);
    }
};

const triggerAnalysisJob = async (job, parentJobId) => {
    console.log(`Triggering analysis job for ${job.jobId}`);
    console.log(`Job submission status:${JSON.stringify(jobSubmissionStatus, null, 2)}`)
    
    // Check if the job is already being submitted
    if (jobSubmissionStatus[job.jobId]) {
        console.log(`Job ${job.jobId} is already being submitted. Skipping.`);
        return;
    }

    // Set submission status to true
    jobSubmissionStatus[job.jobId] = true;

    try {
        const currentJob = getJob(job.jobId);
        if (currentJob.status !== JobStatus.AWAITING_PARENT_JOB) {
            console.log(`Job ${job.jobId} is no longer awaiting parent job. Skipping submission.`);
            return;
        }

        await updateJobStatus(job.jobId, JobStatus.WORKING);

        const result = await run_rss_agent({
            previousJobPaymentHash: parentJobId,
            customPrompt: job.inputField2,
        }, job.jobId);

        if (result.authCategory && result.authCategory === "not-selected") {
            console.warn(`Auth not selected for job ${job.jobId}`);
            await updateJobStatus(job.jobId, JobStatus.ERROR);
        } else {
            await updateJob(result.paymentHash, {
                status: JobStatus.WORKING,
                jobId: result.paymentHash,
                resultPreview: result.transcript ? result.transcript.substring(0, 100) + '...' : 'No preview available'
            });
            console.log(`Successfully submitted analysis job ${job.jobId}`);
        }
    } catch (error) {
        console.error(`Error running analysis job ${job.jobId}:`, error);
        await updateJobStatus(job.jobId, JobStatus.ERROR);
    } finally {
        // Reset submission status
        delete jobSubmissionStatus[job.jobId];
    }
};

export {
    JobStatus,
    addJob,
    addBlockedJob,
    removeJob,
    updateJobStatus,
    updateJob,
    getAllJobs,
    getJob,
    getJobsByParentJobId,
    scanJobStatuses,
    setupPeriodicRefresh,
    fetchTranscriptData,
    triggerAnalysisJob,
    cancelPeriodicRefresh
};