import React, { useState, useEffect } from 'react';
import { FileStack, ListTodo, Youtube, PenTool, Quote, MessageSquare, Twitter, Linkedin, Instagram } from 'lucide-react';

const ContentSelectionButtons = ({ onActionSelect }) => {
    const [ctaStep, setCtaStep] = useState('initialCTA');
    const [title, setTitle] = useState('Want to Create Content with this Transcript?');
    const orangeButtonClass = `relative bg-gradient-to-r from-[#f8801e] to-[#f84c1e] text-white py-2 md:py-3 px-4 md:px-6 rounded-lg 
                               font-semibold text-base md:text-xs
                               transition-all duration-100 ease-in-out
                               hover:-translate-y-1 
                               focus:outline-none
                               group
                               flex items-center justify-center`;

    const whiteButtonClass = `relative bg-white text-black py-2 md:py-3 px-4 md:px-6 rounded-lg 
                               font-semibold text-base md:text-xs
                               transition-all duration-100 ease-in-out
                               hover:-translate-y-1 
                               focus:outline-none
                               group
                               flex items-center justify-center`;

    useEffect(() => {
        if (ctaStep === 'initialCTA') {
            setTitle('Want to Create Content with this Transcript?');
        } else if (ctaStep === 'accepted') {
            setTitle('Select Content Type');
        }
    }, [ctaStep]);

    const contentTypes = [
        { id: 'Summary', label: 'Summary', icon: FileStack },
        { id: 'Show Notes', label: 'Show Notes', icon: ListTodo },
        { id: 'YouTube Description', label: 'YT Description', icon: Youtube },
        { id: 'Article', label: 'Blog Post', icon: PenTool },
        { id: 'Quotes', label: 'Top Quotes', icon: Quote },
        { id: 'Custom Prompt', label: 'Custom Prompt', icon: MessageSquare },
        { id: 'Twitter Post', label: 'Twitter Post', icon: Twitter },
        { id: 'LinkedIn Post', label: 'LinkedIn Post', icon: Linkedin },
        { id: 'IG Caption', label: 'IG Caption', icon: Instagram },
    ];

    const handleYes = () => {
        setCtaStep('accepted');
    };

    const handleNo = () => {
        setCtaStep('declined');
        onActionSelect(null);  // Return nothing when "No" is clicked
    };

    if (ctaStep === 'declined') {
        return null;  // Render nothing if the user clicked "No"
    }

    return (
        <div className="content-selection-buttons bg-gray-800 rounded-lg p-4 md:p-6">
            <h4 className="text-md md:text-md font-bold mb-4 text-white">{title}</h4>
            {ctaStep === 'initialCTA' && (
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handleYes}
                        className={orangeButtonClass}
                        style={{fontSize:'16px'}}
                    >
                        Yes
                    </button>
                    <button
                        onClick={handleNo}
                        className={whiteButtonClass}
                        style={{fontSize:'16px'}}
                        >
                        No
                    </button>
                </div>
            )}
            {ctaStep === 'accepted' && (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {contentTypes.map((type) => {
                        const IconComponent = type.icon;
                        return (
                            <button
                                key={type.id}
                                onClick={() => onActionSelect({ id: type.id, label: type.label })}
                                className={orangeButtonClass}
                            >
                                <IconComponent className="mr-2 w-5 h-5" />
                                <span className="relative">{type.label}</span>
                                <span className="absolute inset-0 rounded-lg transition-all duration-100 ease-in-out
                                                 group-hover:border-2 group-hover:border-white 
                                                 group-hover:shadow-[0_0_15px_5px_rgba(100,100,100,0.8)]"></span>
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ContentSelectionButtons;