import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BeatLoader } from 'react-spinners';
import { defaultRSSPrompts, contentTypes } from '../nip105-client';
import { ArrowRight} from 'lucide-react';


const ContentSelectionComponent = ({setPromptOption: setFinalPromptOption, handleBackClick, isLoading}) => {
  const [customPromptVisible, setCustomPromptVisible] = React.useState(false);
  const [currentPrompt, setCurrentPrompt] = React.useState(null);
  const [additionalContext, setAdditionalContext] = React.useState(null);

  React.useEffect(() => {
    if(!currentPrompt?.customPromptText && currentPrompt?.id) {
      if(currentPrompt?.id === 'Custom Prompt' && currentPrompt?.customPromptText === null){
        setCustomPromptVisible(true);
      }
      setCurrentPrompt({customPromptText:defaultRSSPrompts[currentPrompt.id],id:currentPrompt.id,label:currentPrompt.label});
    }
  }, [currentPrompt]);

    const handleArrowTap = () => {
      setCustomPromptVisible(!customPromptVisible);
    };

    const backDidClick = () => {
      setCurrentPrompt(null);
      setCustomPromptVisible(false);
      handleBackClick();
    }

    const handleCustomPromptChange = (event) => {
      setCurrentPrompt(prevPrompt => ({
        ...prevPrompt,
        customPromptText: event.target.value
      }));
    };

    const handleAdditionalContextChange = (event) => {
      setAdditionalContext(event.target.value);
    };
  
    const carouselItems = [
      {
        image:'https://megaphone.imgix.net/podcasts/8e5bcebc-ca16-11ee-89f0-0fa0b9bdfc7c/image/c2c595e6e3c2a64e6ea18fb6c6da8860.jpg',
        title:'Show Notes from JRE #2206 with Chamath Palihapitiya',
        description:`**Episode Title**: The Joe Rogan Experience: Pushing Boundaries and Challenging Perspectives

        **Episode Description**: In this episode, Joe Rogan delves into the importance of pushing boundaries and challenging conventional perspectives. From discussing ...

        **Key Takeaways**:
        - Embrace diverse messengers to convey important messages effectively.
        - Challenge conventional thinking and explore alternative viewpoints.
        - Encourage curiosity and deep exploration in various fields.
        - Consider the value of multiple perspectives in shaping opinions and decisions.

        **Guest Information**: This episode features Joe Rogan as the host, sharing his insights and engaging in thought-provoking discussions.

        **Resources and Links**: 
        - [Joe Rogan Podcast](https://www.joerogan.com/podcast)
        - [Call of Duty](https://www.callofduty.com/)

        **Call to Action**: Dive into the world of critical thinking and exploration by listening to more episodes of The Joe Rogan Experience. Subscribe to stay updated on engaging conversations and diverse perspectives.
        `
      },
      { 
        image: 'https://d3t3ozftmdmh3i.cloudfront.net/production/podcast_uploaded_nologo/24348194/24348194-1658967098458-7fee1dac7e4b4.jpg',
        title: 'Transcript from Rabbit Hole Recap', 
        description: "Is necessary for an open society in electronic age privacy is not secrecy a private matter something one doesn't want the whole world to know but a secret matter something one doesn't want anybody to know privacy is the power to selectively reveal oneself to the world If 2 parties have some sort of dealings then each has a memory of their interaction. Each party can speak about their own memory of this. How could anyone prevent it? One could pass laws against it, but the freedom of speech Even more than privacy is fundamental to an open."
      },
      { 
        image: 'https://d3t3ozftmdmh3i.cloudfront.net/production/podcast_uploaded_nologo/24348194/24348194-1658967098458-7fee1dac7e4b4.jpg',
        title: 'Summary from RHR #302', 
        description: 
        `1. TLDR: The podcast episode discusses the importance of privacy...
        2. Key Points:
          - The reaction to the news about the Samurai wallet..
          - The Nostr app has introduced new features...
        3. Top Quotes:
          - "Do we have freedom of speech? Do we have the right to privacy..."
        4. Conclusion: The podcast episode delves into the intersection of privacy, freedom of speech...`
      },
      {
        image:'https://episodes.castos.com/6626b866f2af87-36468692/images/podcast/covers/c1a-9mg94-gd41kj0kcnzg-s1m7fc.jpg',
        title:'Blog Post: CASCDR on AI Unchained',
        description:`The Power of Curating Information with AI

          In a recent podcast episode, the discussion centered around the use of AI tools to curate information and streamline content consumption. The hosts highlighted the benefits of using AI modules to sift through vast amounts of data and present only the most relevant information to users...
        `
      }
    ];
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: true,
    };
  
    return (
        <div className="content-selection-container max-w-3xl mx-auto px-4">
          <div className="flex items-center justify-start mb-6">
            <button 
              onClick={backDidClick}
              className="text-white hover:text-orange-500 transition-colors duration-200 mr-4"
              aria-label="Go back"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </button>
            {/* <h2 className="text-2xl md:text-2xl font-bold text-white">(Optional 2/2) Make AI Content with Your Media</h2> */}
          </div>
          <div className="button-container bg-white bg-opacity-10 rounded-lg p-4 md:p-6 mb-4">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 justify-items-center">
              {contentTypes.map((type) => {
                const IconComponent = type.icon;
                return (
                  <button
                    key={type.id}
                    onClick={() => setCurrentPrompt({ id: type.id, label: type.label, customPromptText:null })}
                    className="w-full relative bg-gradient-to-r from-[#f8801e] to-[#f84c1e] text-white py-2 md:py-3 px-4 md:px-6 rounded-lg 
                               font-semibold text-base md:text-lg
                               transition-all duration-100 ease-in-out
                               hover:-translate-y-1 
                               focus:outline-none
                               group
                               flex items-center justify-center"
                    disabled={isLoading}
                  >
                    <IconComponent className="mr-2 w-5 h-5" />
                    <span className="relative">{type.label}</span>
                    <span className="absolute inset-0 rounded-lg transition-all duration-100 ease-in-out
                                     group-hover:border-1 group-hover:border-white 
                                     group-hover:shadow-[0_0_15px_5px_rgba(75,75,75,0.8)]"></span>
                  </button>
                );
              })}
            </div>
          </div>

            <div> 
              <div className={`custom-prompt-header`} onClick={handleArrowTap} style={{marginBottom:'16px'}}>
                <div className={`arrow ${customPromptVisible ? 'down' : 'right'}`}></div>
                <span>{"Advanced"}</span>
              </div>
              {customPromptVisible && (
                <div> 
                  <h2 style={{fontSize:'18px'}}>Customize Your Prompt (Optional)</h2>
                  <textarea
                    value={currentPrompt?.customPromptText ? currentPrompt?.customPromptText : ''}
                    onChange={handleCustomPromptChange}
                    placeholder="Enter your custom prompt here..."
                    className={`prompt-options-textarea`}
                    style={{marginBottom:'16px'}}
                  />
                  <h2 style={{fontSize:'18px'}}>Additional Context (Optional)</h2>
                  <textarea
                    value={additionalContext ? additionalContext : ''}
                    onChange={handleAdditionalContextChange}
                    placeholder="Provide Additional Context on the file to improve accuracy: i.e. This is from Joe Rogan's podcast with Duncan Trussell"
                    className={`additional-context-textarea`}
                    style={{marginBottom:'16px'}}
                  />
                </div>
              )}
            </div>
          
          <div className="flex justify-end mb-8">
          <button
              onClick={() => setFinalPromptOption({id: currentPrompt.id, label: currentPrompt.label, customPromptText: (currentPrompt.customPromptText + (additionalContext ? 'Please note the additional context provided by the user:' + additionalContext : ""))})}
              className={`bg-orange-800 opacity-${(isLoading || currentPrompt === null || currentPrompt === "") ? '50 ' : '100 hover:bg-white hover:text-black'} text-white py-2 px-4 rounded-lg 
                         font-semibold text-base transition-all duration-100 ease-in-out
                         border-2 border-gray-800
                          focus:outline-none group flex items-center
                          `}
              disabled={isLoading || currentPrompt === null || currentPrompt?.customPromptText === ""}
            >
              {isLoading ? <span className="flex items-center"> Loading <BeatLoader size={10} color="black" /></span> : <span className="flex items-center ml-4 mr-4">Run</span>}
            </button>
            <button
              onClick={() => setFinalPromptOption({ id: "Transcript", label: "Transcript" })}
              className={`bg-black text-white py-2 px-4 rounded-lg ml-6
                         font-semibold text-base transition-all duration-100 ease-in-out
                         border-2 border-gray-800
                         opacity-${(isLoading) ? '50 ' : '100 hover:bg-white hover:text-black'}
                          focus:outline-none group flex items-center`}
              disabled={isLoading}
            >
              Skip
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </div>
    
          {/* <div className="carousel-container my-8 md:my-12">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-white">Examples</h2>
            <div className="max-w-2xl mx-auto">
              <Slider {...settings}>
                {carouselItems.map((item, index) => (
                  <div key={index} className="px-2 md:px-4">
                    <div className="bg-gradient-to-r from-[#1e1e1e] to-[#0e0e0e] rounded-lg shadow-xl p-4 md:p-6 aspect-[2]">
                      <div className="flex flex-col h-full">
                        <div className="flex items-center mb-3 md:mb-4">
                          <img src={item.image} alt={item.title} className="w-16 h-16 md:w-20 md:h-20 object-cover rounded-lg mr-3 md:mr-4" />
                          <h3 className="text-xl md:text-2xl font-bold text-white">{item.title}</h3>
                        </div>
                        <p className="text-sm md:text-base text-white opacity-90 flex-grow whitespace-pre-line">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div> */}
        </div>
    );
};

export default ContentSelectionComponent;