import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkImages from 'remark-images';
import remarkCapitalize from 'remark-capitalize';
import ResizeImage from 'react-resize-image';

const MarkdownContentView = ({ title, content, onCopy }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = () => {
    onCopy(content);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const renderers = {
    // Custom renderer for links
    a: ({ href, children }) => (
      <a style={{textDecoration: 'underline'}} href={href}>
        {children}
      </a>
    ),
    img: ({src, alt, title}) => (
        <ResizeImage src={'https://imgur.com/jwj0vXe'} alt={alt} title={title} width={300} height={200} />
    ),
    h1: ({children}) => (
      <h1 style={{fontSize: '2.0rem', fontWeight: 'bold', marginBottom: '1.2rem'}}>{children}</h1>
    ),
    h2: ({children}) => (
      <h2 style={{fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '1.2rem'}}>{children}</h2>
    ),
    h3: ({children}) => (
      <h3 style={{fontSize: '1.6rem', fontWeight: 'italicized', marginBottom: '1.0rem'}}>{children}</h3>
    ),
    ul: ({children}) => (
      <ul style={{margin: '1.2rem'}}>{children}</ul>
    ),
    li: ({children}) => (
      <li style={{marginBottom: '.8rem'}}>- {children}</li>
    ),
    p: ({children}) => (
      <p style={{marginBottom: '.8rem'}}>{children}</p>
    )
  };
  const buttonClass = "relative bg-gradient-to-r from-[#f8801e] to-[#f84c1e] text-white py-2 md:py-3 px-4 md:px-6 rounded-lg font-semibold text-base md:text-sm transition-all duration-100 ease-in-out border-2 border-orange-600 hover:border-white hover:border-2 focus:outline-none group"


  return (
    <div className="bg-gray-800 rounded-lg p-6 mt-6">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <div className="markdown-content bg-gray-900 p-4 rounded">
        <ReactMarkdown 
          children={content} 
          remarkPlugins={[remarkGfm, remarkImages, remarkCapitalize]} 
          components={renderers}
        />
      </div>
      <button 
        onClick={handleCopy}
        className={buttonClass}
        style={{marginTop: '1.2rem'}}
      >
        {isCopied ? 'Copied!' : 'Copy Content'}
      </button>
    </div>
  );
};

export default MarkdownContentView;