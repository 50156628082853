import React, { useState, useRef, useEffect} from 'react';
import './SDComponent.css'; // Replace with your custom CSS file
import { run_sd_example } from '../../common/nip105-client';
import gpt_icon from '../../media/images/services/image2-text-v2.svg';
import options from '../../common/constants';
import { BeatLoader } from 'react-spinners';
import { Textarea, Button } from "@material-tailwind/react";
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';
import ImageHistoryComponent from '../../common/components/ImageHistoryComponent';


const SDComponent = ({paymentChoiceStatus, setPaymentChoiceStatus}) => {
  const [selectedSDModel, setSelectedSDModel] = useState('dream-shaper-8797');
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const { activeTab, setActiveTab } = useActiveTab();
  const chatRef = useRef(null);

  const [showCopyBubble, setShowCopyBubble] = useState(false); // State to control the visibility of the copy notification bubble
  const [bubbleText,setBubbleText] = useState("");
  // Function to copy image URL to clipboard
  const copyImageUrlToClipboard = () => {
    if (imageURL) {
      navigator.clipboard.writeText(imageURL).then(() => {
        handleClipboardText();
      }).catch((err) => {
        console.error('Failed to copy image URL:', err);
      });
    }
  };

  function handleClipboardText(){
    setBubbleText("Image URL Copied to Clipboard")
    setShowCopyBubble(true); // Show the bubble on successful copy
    setTimeout(() => setShowCopyBubble(false), 2000); // Hide the bubble after 2 seconds
  }

  const updateImageStorage = (imageUrl, prompt) => {
      const newImageEntry = {
          date: new Date().toISOString(),
          url: imageUrl,
          thumbnail: imageUrl, // Assuming thumbnail is the same as the image URL
          prompt: prompt
      };

      const storedImages = JSON.parse(localStorage.getItem('imageRequests') || '[]');
      storedImages.push(newImageEntry);
      localStorage.setItem('imageRequests', JSON.stringify(storedImages));
      window.dispatchEvent(new CustomEvent('onImageStorageUpdated'));
  };


  const sendMessage = async () => {
    if (inputText.trim() !== '') {
      setIsLoading(true);
      performJob(inputText);
    }
  };

  async function performJob(inputText) {
    try {
      const result = await run_sd_example(inputText, selectedSDModel);
      
      if (result.authCategory && result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        setIsLoading(false);
        return;
      }
  
      console.log(`Stable Diffusion Result:`, result.output[0]);
  
      // Function to check the URL until a valid response is received or timeout occurs
      async function pollImageUrl(url, attempts = 1) {
        try {
          const response = await fetch(url);
          if (response.ok) { // Checks if the status is 200-299
            const imageUrl = await response.url;
            setTimeout(() => {
              setImageURL(imageUrl);
              setIsLoading(false);
            }, 10000); // Set image after 10 seconds if response is successful
          } else {
            throw new Error('Server responded with an error');
          }
        } catch (error) {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: Failed to get a valid response, retrying in 5 seconds...`);
            setTimeout(() => {
              pollImageUrl(url, attempts + 1); // Retry after 5 seconds
            }, 5000);
          } else {
            console.log("Failed after 3 attempts, giving up.");
            setIsLoading(false);
          }
        }
      }
  
      if (result.output[0]) {
        await pollImageUrl(result.output[0]); // Start polling the image URL
      } else {
        console.log("No image URL provided.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error in performJob:', error);
      setIsLoading(false);
    }
  }
  

  const handleDropdownChange = (event) => {
    setSelectedSDModel(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  useEffect(() => {
    if(imageURL){
      setBubbleText("Image Generation Finished! Left click to copy link!")
      setShowCopyBubble(true); // Show the bubble on successful copy
      setTimeout(() => setShowCopyBubble(false), 3000);
      updateImageStorage(imageURL, inputText);
      setInputText('');
    }
  }, [imageURL]);

  useEffect(() => {
    const handlePopState = () => {
        // Extract the tab parameter from the URL
        const params = new URLSearchParams(window.location.search);
        const tab = params.get('tab');

        // Update the active tab based on the URL, or reset to default if no tab is specified
        if (tab) {
            setActiveTab(tab);
        } else {
            // Reset to default state if back at main page without specific tab
            setActiveTab(null); // Or whatever your default state should be
        }
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
}, [setActiveTab]);

  return (
    <div className="sd-history-and-chat-container">
        <ImageHistoryComponent onImageCopy={handleClipboardText}/>
      <div className="sd-chat-container">
        {showCopyBubble && (
          <div className="bubble-alert">
            {bubbleText}
          </div>
        )}
        <div className="sd-header-container">
          <div 
            className="sd-gpt__icon w-16 h-16 rounded-xl overflow-hidden bg-gradient-to-r from-orange-500 to-red-500 flex-shrink-0 mr-4" 
            style={{
              borderRadius: '12px',
              height: '80px',
              width: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px'  // This adds 4 pixels of padding inside the container
            }}
          >
            <img 
              src={gpt_icon} 
              alt="GPT Icon" 
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                padding: '0px'
              }} 
            />
          </div>
          <h1 className="sd-gpt-title">Stable Diffusion: Text to Image Prompt</h1>
        </div>
        <div className="sd-image-container mt-4 md:mt-0 md:w-1/2 lg:w-1/3 xl:w-1/4">
          {imageURL ? (
            <img
              className="w-full h-auto"
              src={imageURL}
              alt="Generated Content"
              onClick={copyImageUrlToClipboard} // Add this line
            />
          ) : (
            <p className="sd-no-image">No Image Yet... Enter a Prompt to display one</p>
          )}
        </div>
        <div className="sd-chat-input">
        <Textarea
          value={inputText}
          className="text-white bg-black w-full md:w-96 px-4 py-2 rounded-md mb-4 ml-2 md:ml-0"
          style={{
            minHeight: '15vh',
            backgroundColor: isLoading ? 'gray' : 'black',
          }}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your text to image prompt..."
          disabled={isLoading}
        />



          <div className="sd-action-container">
            <div className="sd-dropdown-container" style={{color:'black', paddingLeft:'20px'}}>
              <div style={{color:'white'}}><strong>SD Model</strong></div>
              <select
                className="sd-nodrag-dropdown"
                onChange={handleDropdownChange}
                value={selectedSDModel}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <Button
              color="blue"
              onClick={sendMessage}
              className="sd-run-button"
              style={{
                background: 'linear-gradient(to right, #F81E1E, #f8801e)',
                color: 'white',
                width: '120px',
                zIndex:'0'
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <BeatLoader color="white" size={8} loading={true} />
              ) : (
                <strong>Run</strong>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SDComponent;
