import React, { useState, useRef, useEffect } from 'react';
import './JamieComponent.css'; 
import { run_whisper_example, run_rss_agent, createGuidFromUrl } from '../../common/nip105-client';
import { BeatLoader } from 'react-spinners';
import icon from '../../media/images/services/jamie-logo-final.png';
import CompartmentalizedTranscriptModal from '../../common/components/modal/CompartmentalizedTranscriptModal';
import EpisodeItemTableRow from '../../common/components/menus/EpisodeItemTableRow';
import TranscriptModal from '../../common/components/modal/TranscriptModal';
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';
import ContentSelectionComponent from '../../common/components/ContentSelectionComponent';
import PromptOptionsComponent from '../../common/PromptOptionsComponent';
import DebouncedInput from '../../common/components/menus/DebouncedInput';
import { addBlockedJob } from '../../common/components/jobHistoryManager';
import { checkEligibility } from '../../lib/accountManagement';
import BubbleAlert from '../../common/components/modal/BubbleAlert';
import { fetchAndParseFeeds, fetchAndParseFeedData, getFountainLink } from '../../lib/rssHelpers';
import PlaceholderImage from '../../media/images/misc/cascdr-placeholder.png';
import { ArrowUp } from 'lucide-react';


const JamieComponent = ({ paymentChoiceStatus, setPaymentChoiceStatus }) => {
  // Existing state
  const [remoteURL, setRemoteURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false); // Loading for search
  const [isLoadingSelect, setIsLoadingSelect] = useState(false); // Loading for select action
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [initialToggleTab, setInitialToggleTab] = useState('Transcript');
  const [isAiAnalysisOpen, setIsAiAnalysisOpen] = useState(false);
  const [aiAnalysis, setAiAnalysis] = useState('');
  const [transcriptData, setTranscriptData] = useState(null);
  const [paymentHash, setPaymentHash] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentStep, setCurrentStep] = useState("uploadFile");
  const [promptOption, setPromptOption] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [currentEpisodeGUID, setCurrentEpisodeGUID] = useState(null);
  const fileInputRef = useRef(null);
  const { activeTab, setActiveTab } = useActiveTab();
  const [acceptedFileTypes] = useState(['mp3', 'wav', 'ogg', 'flac', 'm4a', 'mp4', 'mov', 'avi', 'wmv', 'flv', 'webm']);
  
  // New state for RSS functionality
  const [mode, setMode] = useState('file'); // 'file' or 'rss'
  const [podcastInput, setPodcastInput] = useState('');
  const [debouncedPodcastInput, setDebouncedPodcastInput] = useState('');
  const [feeds, setFeeds] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [selectedFeed, setSelectedFeed] = useState(null);
  const jamieLogoRef = useRef(null); // Ref for the Jamie logo
  const [showScrollToTop, setShowScrollToTop] = useState(false); // New state for scroll button visibility


  // Existing constants
  const MAX_FILE_SIZE = 250 * 1024 * 1024;

  useEffect(() => {
    const staticFeeds = [
      {
        id: 6786106,
        title: "The Joe Rogan Experience",
        url: "https://feeds.megaphone.fm/GLT1412515089",
        description: "The official podcast of comedian Joe Rogan.",
        author: "Joe Rogan",
        image: "https://megaphone.imgix.net/podcasts/8e5bcebc-ca16-11ee-89f0-0fa0b9bdfc7c/image/c2c595e6e3c2a64e6ea18fb6c6da8860.jpg"
      },
      {
        id: 1000839,
        title: "Bitcoin Audible",
        url: "https://feeds.castos.com/mj96z",
        originalUrl: "https://anchor.fm/s/80d5cfc/podcast/rss",
        link: "https://bitcoinaudible.com/",
        description: "The Best in Bitcoin made Audible.",
        author: "Guy Swann",
        ownerName: "Guy Swann",
        image: "https://episodes.castos.com/6626b866f2af87-36468692/images/podcast/covers/c1a-9mg94-o87n4gnwav1j-6d2xb6.jpg"
      },
      {
        id: 3955537,
        title: "Thriller \"A Bitcoin Zine\"",
        url: "https://feeds.transistor.fm/thriller-premium",
        originalUrl: "https://api.substack.com/feed/podcast/9895.rss",
        link: "https://www.thrillerbitcoin.com",
        description: "Thriller is a local austin bitcoin zine. | Listen to the pod http://thriller.transistor.fm | ⚡️thriller@getalby.com",
        author: "Thriller X Recordings",
        ownerName: "Thriller X Recordings",
        image: "https://img.transistor.fm/flZJC8zviqt7OAbY5RXG072wag-t6IvdBZKEzHUCfgI/rs:fill:3000:3000:1/q:60/aHR0cHM6Ly9pbWct/dXBsb2FkLXByb2R1/Y3Rpb24udHJhbnNp/c3Rvci5mbS9zaG93/LzIzMjQwLzE2NjQ1/NTE2NjItYXJ0d29y/ay5qcGc.jpg"
      },
      // {
      //   id: 726364,
      //   title: "Bitcoin Fixes This",
      //   url: "https://anchor.fm/s/2a4e8034/podcast/rss",
      //   originalUrl: "https://anchor.fm/s/2a4e8034/podcast/rss",
      //   link: "https://programmingbitcoin.com",
      //   description: "We explore the impact that Bitcoin will have in all aspects of society.",
      //   author: "Jimmy Song",
      //   ownerName: "Jimmy Song",
      //   image: "https://d3t3ozftmdmh3i.cloudfront.net/production/podcast_uploaded_nologo/6997877/6997877-1594782510386-e2bbc8c35dd3d.jpg"
      // },
      // {
      //   id: 1143799,
      //   title: "The Bitcoin Standard Podcast",
      //   url: "https://feeds.buzzsprout.com/1849151.rss",
      //   originalUrl: "https://www.spreaker.com/show/3478385/episodes/feed",
      //   link: "https://saifedean.com/podcast/",
      //   description: "Saifedean's The Bitcoin Standard Podcast is the place to discuss Bitcoin and economics from the Austrian school's perspective.",
      //   author: "Dr. Saifedean Ammous",
      //   ownerName: "Dr. Saifedean Ammous",
      //   image: "https://storage.buzzsprout.com/m4cl5ql1mdv17q3ktf94m0ookj0d?.jpg"
      // },
      // {
      //   id: 5738301,
      //   title: "Blue Collar Bitcoin",
      //   url: "https://feed.podbean.com/bluecollarbitcoinpodcast/feed.xml",
      //   originalUrl: "https://feed.podbean.com/bluecollarbitcoinpodcast/feed.xml",
      //   link: "https://bluecollarbitcoinpodcast.podbean.com",
      //   description: "Firefighters explore economics, finance, & the most important monetary technology of the 21st century—Bitcoin.",
      //   author: "Blue Collar Bitcoin Podcast",
      //   ownerName: "Blue Collar Bitcoin Podcast",
      //   image: "https://pbcdn1.podbean.com/imglogo/image-logo/11936639/Podcast_Logo_622237paty.png"
      // }
    ];
    setFeeds(staticFeeds);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (jamieLogoRef.current) {
        const logoBottom = jamieLogoRef.current.getBoundingClientRect().bottom;
        setShowScrollToTop(logoBottom < 0); // Show button when logo is out of view
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if(mode === 'file'){
      setSelectedFeed(null)
      setRemoteURL("")
    }
  }, [mode]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPodcastInput(podcastInput);
    }, 1000); // 1-second debounce time

    return () => clearTimeout(handler); // Clear the timeout if input changes
  }, [podcastInput]);

  // Fetch feeds when debounced input changes
  useEffect(() => {
    const fetchFeeds = async () => {
      if (debouncedPodcastInput) {
        setIsLoadingSearch(true);
        try {
          const feedsData = await fetchAndParseFeeds(debouncedPodcastInput);
          setFeeds(feedsData);
        } catch (error) {
          setAlertMessage("Error fetching feeds please try again")
          setShowAlert(true);
          console.error('Error fetching feeds:', error);
        } finally {
          setIsLoadingSearch(false);
        }
      }
    };
    fetchFeeds();
  }, [debouncedPodcastInput]);

  useEffect(() => {
    if(currentStep === "selectAnalysisType" && promptOption != null){
      startTranscriptJob();
    }
  }, [promptOption]);

  // RSS-related handlers
  const handleSelectPodcast = async (feedId, feedUrl) => {
    setIsLoadingSelect(true);
    try {
      const episodesData = await fetchAndParseFeedData(feedUrl, feedId);
      setEpisodes(episodesData);
      // Set the selected feed when selecting a podcast
      setSelectedFeed({ id: feedId, url: feedUrl });
    } catch (error) {
      console.error('Error fetching episodes:', error);
    } finally {
      setIsLoadingSelect(false);
    }
  };

  const handleEpisodeSelect = async (episodeUrl, episodeGUID) => {
    if (!episodeUrl) {
      showBubbleAlert("Invalid episode URL");
      return;
    }
    setRemoteURL(episodeUrl);
    setCurrentEpisodeGUID(episodeGUID);
    setCurrentStep("selectAnalysisType");
  };

  // Existing handlers and utility functions
  const validateFileSize = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      showBubbleAlert("File size exceeds 250MB limit. Please choose a smaller file.");
      return false;
    }
    return true;
  };

  const validateFileType = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return acceptedFileTypes.includes(fileExtension);
  };

  const validateRemoteURL = (url) => {
    const fileExtension = url.split('.').pop().toLowerCase();
    return acceptedFileTypes.includes(fileExtension);
  };

  const showBubbleAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 4000);
  };

  const resetFileInputs = () => {
    setRemoteURL('');
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleInputChange = (event) => {
    const url = event.target.value;
    setRemoteURL(url);
    if (url && !validateRemoteURL(url)) {
      showBubbleAlert(`Invalid file type. Accepted types are: ${acceptedFileTypes.join(', ')}`);
      setRemoteURL('');
    }
  };

  const handleKeyPress = (event) => {
    setSelectedFile(null);
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  const handleOptionChange = (newOption) => {
    // console.log(`handleOptionChange:${newOption}`)
  };

  const handleCustomPromptChange = (newPrompt) => {
    console.log(`handleCustomPromptChange JamieComponent:${newPrompt}`)
    setCustomPrompt(newPrompt);
  };

  const handleBackClick = () => {
    setCurrentStep("uploadFile");
    setPromptOption(null);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (validateFileType(file)) {
        setSelectedFile(file);
        handleFileLoaded(file);
      } else {
        showBubbleAlert(`Invalid file type. Accepted types are: ${acceptedFileTypes.join(', ')}`);
        resetFileInputs();
      }
    }
  };

  const handleButtonClick = async () => {
    if(selectedFile && !validateFileSize(selectedFile)){
      return;
    }
    if (!remoteURL && !selectedFile) {
      showBubbleAlert("Please provide a file or a URL.");
      return;
    }
    setCurrentStep("selectAnalysisType");
  };

  const handleFileLoaded = (file) => {
    if(file){
      const url = URL.createObjectURL(file);
      const audio = new Audio(url);

      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration > 60 * 300) {
          showBubbleAlert("Audio is limited to 5 hours or less for this service. Please check back for updates for when we upgrade.");
          setSelectedFile(null);
        }
        URL.revokeObjectURL(url);
      });
    }
  };

  const handleJobCompletion = (jobId) => {
    setActiveTab('history');
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('jobId', jobId);
    window.history.pushState({}, '', newUrl);
  };

  const checkRemoteFileDuration = async (url) => {
    return new Promise((resolve, reject) => {
      const media = new Audio(url);

      media.onloadedmetadata = () => {
        const durationInMinutes = media.duration / 60;
        if (durationInMinutes > 300) {
          reject(new Error("The media file exceeds the 300 minute duration limit."));
        } else {
          resolve(media.duration);
        }
        URL.revokeObjectURL(media.src);
      };

      media.onerror = () => {
        reject(new Error("Failed to load the media file."));
      };
    });
  };

  async function startTranscriptJob(){
    setIsLoading(true);
    try {
      if (remoteURL) {
        await checkRemoteFileDuration(remoteURL);
      }
      await performJob(remoteURL, selectedFile);
    } catch (error) {
      showBubbleAlert(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const performJob = async (remoteLink, localLink) => {
    try {
      const result = await run_whisper_example(remoteLink, localLink, true, currentEpisodeGUID);
  
      if (result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        setIsLoading(false);
        return;
      }
  
      if(!result.success){
        showBubbleAlert("An error occurred during processing.");
      } else {
        let deeplinkedJobId = result.paymentHash;
        if (promptOption && promptOption.id !== 'Transcript') {
          const serviceName = 'Jamie';
          const inputField1 = promptOption.label;
          // Get the prompt text from the promptOption object
          const inputField2 = promptOption.promptText || promptOption.customPromptText;
          const parentJobId = result.paymentHash;
          const outputType = promptOption.id;

          const blockedJob = addBlockedJob(serviceName, inputField1, inputField2, parentJobId, outputType, 'Waiting for transcript completion');
          deeplinkedJobId = blockedJob.jobId;
        }
        setIsUploadModalOpen(false);
        if(deeplinkedJobId){
          handleJobCompletion(deeplinkedJobId);
        }
      }
    } catch (error) {
      alert("An error occurred during processing.");
    } finally {
      setIsLoading(false);
    }
  };

  const runAnalysisJob = async () => {
    setInitialToggleTab('AI Analysis');
    const previousJobPaymentHash = paymentHash;
    const data = {
      previousJobPaymentHash: previousJobPaymentHash,
      customPrompt: customPrompt
    };

    setIsLoading(true);
    try {
      const result = await run_rss_agent(data);
      console.log(`got result:`, result);
      if (result.authCategory && result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
      } else {
        console.log(`rss_agent result:`, result);
        setAiAnalysis(result.transcript);
        setIsAiAnalysisOpen(true);
      }
    } catch (error) {
      console.error('Error running YouTube agent:', error);
      showBubbleAlert('Failed to process the request. Please try again.');
    }
    setIsLoading(false);
  };

  // Segmented Control Component
  const SegmentedControl = () => (
    <div className="flex rounded-lg bg-black/10 p-1 mb-6">
      <button
        className={`flex-1 px-4 py-2 rounded-md text-sm font-bold ${
          mode === 'file' ? 'bg-black text-white' : 'text-black'
        }`}
        onClick={() => setMode('file')}
        style={{fontSize:'18px'}}
      >
        Use File
      </button>
      <button
        className={`flex-1 px-4 py-2 rounded-md text-sm font-bold ${
          mode === 'rss' ? 'bg-black text-white' : 'text-black'
        }`}
        style={{fontSize:'18px'}}
        onClick={() => setMode('rss')}
      >
        Search RSS
      </button>
    </div>
  );

  // RSS Search Component

  // Update the RSSSearch component:
  const RSSSearch = () => {
    const [imageLoaded, setImageLoaded] = useState({}); // Track loading state for each image
  
    // Initialize imageLoaded state only when feeds change
    useEffect(() => {
      setImageLoaded((prevLoadedState) => {
        const newLoadedState = { ...prevLoadedState };
        feeds.forEach(feed => {
          if (!(feed.id in newLoadedState)) {
            newLoadedState[feed.id] = false; // Initialize only if not already set
          }
        });
        return newLoadedState;
      });
    }, [feeds]);
  
    const handleImageLoad = (feedId) => {
      setImageLoaded((prevState) => ({ ...prevState, [feedId]: true }));
    };
  
    return (
      <div className="space-y-4">
        {!selectedFeed && (
          <>
            <input
              type="text"
              value={podcastInput}
              onChange={(e) => setPodcastInput(e.target.value)}
              placeholder="Search for a podcast..."
              className="jamie-search-bar"
              autoFocus
            />
  
            {isLoadingSearch ? (
              <div className="loading-spinner">
                <BeatLoader color="white" size={8} />
              </div>
            ) : (
              feeds.length > 0 && (
                <div className="feeds-list space-y-4">
                  {feeds.map(feed => (
                    <div key={feed.id} className="flex items-center space-x-4 bg-white bg-opacity-20 p-4 rounded-xl">
                      <div className="relative w-16 h-16">
                        {/* Placeholder Image */}
                        <img
                          src={PlaceholderImage}
                          alt="Placeholder"
                          className="absolute top-0 left-0 w-full h-full rounded-lg object-cover"
                        />
                        {/* Feed Image, only visible when loaded */}
                        <img
                          src={feed.image}
                          alt={feed.title}
                          className={`absolute top-0 left-0 w-full h-full rounded-lg object-cover transition-opacity duration-500 ${imageLoaded[feed.id] ? 'opacity-100' : 'opacity-0'}`}
                          onLoad={() => handleImageLoad(feed.id)}
                        />
                      </div>
                      <div className="flex-1">
                        <h3 className="font-bold">{feed.title}</h3>
                        <p className="text-sm">{feed.author}</p>
                      </div>
                      <button
                        onClick={() => handleSelectPodcast(feed.id, feed.url)}
                        className="select-button"
                        disabled={isLoadingSelect}
                      >
                        {isLoadingSelect ? <BeatLoader color="white" size={8} /> : 'Select'}
                      </button>
                    </div>
                  ))}
                </div>
              )
            )}
          </>
        )}
  
        {selectedFeed && (
          <div className="space-y-4">
            <button
              onClick={() => {
                setSelectedFeed(null);
                setSelectedFile(null)
                setEpisodes([]);
                setRemoteURL('');
              }}
              className="mb-4 text-white hover:text-orange-400 underline"
            >
              ← Back to podcast search
            </button>
  
            {isLoadingSelect ? (
              <div className="loading-spinner">
                <BeatLoader color="black" size={8} />
              </div>
            ) : episodes.length > 0 ? (
              episodes.map(episode => (
                <EpisodeItemTableRow
                  key={episode.itemUUID}
                  episode={{
                    ...episode,
                    episodeImage: episode.episodeImage || PlaceholderImage // Use placeholder if image is missing
                  }}
                  isLoading={isLoading}
                  onEpisodeSelect={handleEpisodeSelect}
                  showListenButton={false}
                  className="bg-white bg-opacity-20 rounded-xl"
                />
              ))
            ) : (
              <div className="text-center p-4">No episodes found</div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="whisper-container w-full max-w-md sm:max-w-2xl md:max-w-3xl mx-auto px-4 sm:px-6">
      
      {showAlert && (
        <BubbleAlert 
          message={alertMessage}
          backgroundColor="red"
          textColor="white"
          borderColor="white"
        />
      )}
      {showScrollToTop && (
        <button onClick={scrollToTop} className="scroll-to-top-button">
          <ArrowUp size={"3.5vh"} color="white" />
        </button>
      )}
      {/* Header stays outside of colored sections */}
      <div ref={jamieLogoRef} className="whisper-header-container flex flex-col items-center text-center mb-6">
        <div className="whisper__icon w-16 h-16 rounded-xl overflow-hidden bg-gradient-to-r from-[#f8801e] to-[#f84c1e] flex-shrink-0 mb-4">
          <img src={icon} alt="icon" className="w-full h-full object-cover p-2"/>
        </div>
        <div>
          <h1 className="text-3xl sm:text-2xl md:text-3xl font-bold mb-1">Jamie - Your AI Media Assistant!</h1>
          <h3 className="text-xs sm:text-sm md:text-base">Transcribe Then Make Content with Your Audio or Video</h3>
        </div>
      </div>
  
      <div className="content-wrapper">
        {currentStep === "uploadFile" && (
          <>
            {/* Orange section with file upload UI */}
            <div className="bg-gradient-to-r from-[#f8801e] to-[#f84c1e] rounded-t-2xl p-6 text-black">
              <h2 className="text-2xl font-bold mb-4 flex items-center justify-center">
                <span className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                  </svg>
                </span>
                Let's Get a Transcript for Your Media (1/2)
              </h2>
              <SegmentedControl />
              
              {mode === 'file' ? (
  <div
    className={`relative ${isDragging ? 'border-4 border-dashed border-white' : ''}`}
    onDragEnter={handleDragEnter}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    onDrop={handleDrop}
  >
    <div className="mb-4">
      <div className="relative">
        <input 
          type="text" 
          value={remoteURL}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter file URL here"
          className="w-full py-2 px-4 rounded-full bg-white bg-opacity-20 text-black placeholder-black"
        />
        <button className="absolute right-2 top-1/2 transform -translate-y-1/2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>
        </button>
      </div>
    </div>

    <p className="text-center mb-4">Or</p>

    <div className="mb-4">
      <label className="w-full py-2 px-4 rounded-full bg-white bg-opacity-20 text-black flex items-center justify-center cursor-pointer">
        <span className="mr-2">Choose a file or Drag n' Drop</span>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input 
          type="file" 
          className="hidden" 
          onChange={(e) => {
            const file = e.target.files[0];
            if (file && validateFileType(file)) {
              setSelectedFile(file);
              handleFileLoaded(file);
            } else {
              showBubbleAlert(`Invalid file type. Accepted types are: ${acceptedFileTypes.join(', ')}`);
              resetFileInputs();
            }
          }}
          accept={acceptedFileTypes.map(type => `.${type}`).join(',')}
          ref={fileInputRef}
        />
      </label>
      {selectedFile && (
        <p className="mt-2 text-sm">Selected: {selectedFile.name}</p>
      )}
    </div>

    <PromptOptionsComponent
      onOptionChange={handleOptionChange}
      onCustomPromptChange={handleCustomPromptChange}
      showOptionsOnOpen={promptOption?.id === 'Custom Prompt'}
      preselectedPrompt={"Transcript"}
      labelText={"Advanced"}
      customContext={"jamie-context"}
    />

    <button 
      onClick={handleButtonClick}
      className="w-full py-2 px-4 rounded-full bg-black text-white font-semibold hover:bg-opacity-80 transition-all duration-200"
      disabled={isLoading}
    >
      {isLoading ? <BeatLoader color="white" size={8} /> : "Next"}
    </button>
  </div>
) : (
  // Dark theme for RSS search
  <div> </div>
)}
            </div>
          </>
        )}
  {mode === "rss" && currentStep === "uploadFile" &&(
    <div className="bg-[#2D2D2D] rounded-xl text-white p-6 mt-4">
      <RSSSearch />
    </div>
  )}
        {currentStep === "selectAnalysisType" && (
          <ContentSelectionComponent 
            setPromptOption={setPromptOption} 
            handleBackClick={handleBackClick} 
            isLoading={isLoading}
          />
        )}
  
        {/* Modals remain unchanged */}
        {isTranscriptModalOpen && transcriptData && (
          <CompartmentalizedTranscriptModal
            title="Media Transcript"
            transcriptData={transcriptData}
            onClose={() => setIsTranscriptModalOpen(false)}
            onCopy={(text) => navigator.clipboard.writeText(text)}
            onRunAnalysis={runAnalysisJob}
            setCustomPrompt={setCustomPrompt}
            showOptionsOnOpen={true}
            initialToggleTab={initialToggleTab}
            isLoading={isLoading}
          />
        )}
        
        {isAiAnalysisOpen && (
          <TranscriptModal
            title={"Summary"}
            transcript={aiAnalysis}
            onClose={() => setIsAiAnalysisOpen(false)}
            onCopy={(text) => navigator.clipboard.writeText(text)}
          />
        )}
      </div>
    </div>
  );
};

export default JamieComponent;