import React from 'react';
import JobStatusBadge from './JobStatusBadge';
import { FileText, FileStack, ListTodo, Youtube, PenTool, Quote, MessageSquare, Twitter, Linkedin , Instagram } from 'lucide-react';


const JobHistoryDetailsCard = ({ job, isCompact = false, onJobSelect, copiedId, onCopy, shouldShowFullDetails = false }) => {
  const getJobTypeIcon = (jobType) => {
    const iconMap = {
      'Transcript from Link': FileText,
      'Transcript from Upload': FileText,
      'Summary': FileStack,
      'Show Notes': ListTodo,
      'YT Description': Youtube,
      'Blog Post': PenTool,
      'Top Quotes': Quote,
      'Custom Prompt': MessageSquare,
      'LinkedIn Post': Linkedin,
      'Twitter Post': Twitter,
      'IG Caption': Instagram,
    };
    const IconComponent = iconMap[jobType] || FileText;
    return <IconComponent className="w-8 h-8 text-black" />;
  };

  const truncateText = (text, maxLength = 20) => {
    if (!text || text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const getInputField2 = (job) => {
    if (job.inputField1 === 'Transcript from Link') {
      return { label: 'Link', value: job.inputField2 };
    } else if (job.inputField1 === 'Transcript from Upload') {
      return { label: 'File Name', value: truncateText(job.inputField2,20) };
    } else if (['Top Quotes','Summary', 'YT Description', 'Show Notes', 'Blog Post', 'LinkedIn Post', 'Twitter Post', 'IG Caption'].includes(job.inputField1)) {
      return { label: 'Prompt', value: truncateText(job.inputField2, 50) };
    }
    return { label: 'Input', value: job.inputField2 };
  };

  const renderJobField = (label, value) => {
    if (value === undefined || value === null) return null;
    if (typeof value === 'object') {
      console.warn(`Unexpected object for ${label}:`, value);
      return null;
    }
    
    if (label === 'Link' && isValidUrl(value)) {
      return (
        <p>
          <span className="font-semibold">{label}:</span>{' '}
          <a 
            href={value} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-orange-800 hover:text-orange-400 underline"
            onClick={(e) => e.stopPropagation()}
          >
            {truncateText(value)}
          </a>
        </p>
      );
    }

    if (label === 'Job ID' && onCopy) {
      return (
        <p>
          <span className="font-semibold">{label}:</span>{' '}
          <span 
            className="cursor-pointer hover:text-orange-400 transition-colors duration-200"
            onClick={(e) => {
              e.stopPropagation();
              onCopy(value, e);
            }}
            title="Click to copy"
          >
            {truncateText(String(value))}
          </span>
          {copiedId === value && 
            <span className="ml-2 text-orange-500 text-xs">Copied!</span>
          }
        </p>
      );
    }

    if(label === 'Result Preview'){
      return <p><span className="font-semibold">{label}:</span> {truncateText(String(value),80)}</p>;
    }

    return <p><span className="font-semibold">{label}:</span> {String(value)}</p>;
  };

  const inputField2 = getInputField2(job);
  // console.log(`job: ${JSON.stringify(job, null, 2)}`)
  return (
    <div 
      className={`bg-gray-800 rounded-lg p-4 flex border-2 border-transparent ${!isCompact ? 'cursor-pointer hover:shadow-[0_0_15px_5px_rgba(75,75,75,0.8)] transition-all duration-100 ease-in-out hover:-translate-y-1 hover:border-2 hover:border-white' : ''}`}
      onClick={() => !isCompact && onJobSelect && onJobSelect(job.jobId)}
    >
      <div className="w-16 h-16 rounded-xl overflow-hidden bg-gradient-to-r from-[#f8801e] to-[#f84c1e] flex-shrink-0 mr-4 flex items-center justify-center">
        {getJobTypeIcon(job.inputField1)}
      </div>
      <div className="flex-grow" style={{marginRight:'24px'}}>
        <h2 className="text-xl font-bold">{job.inputField1 || 'Unknown Job Type'}</h2>
        {renderJobField(inputField2.label, inputField2.value)}
        <div className="text-sm text-gray-400">
          {shouldShowFullDetails && renderJobField('Date', job.date ? new Date(job.date).toLocaleString() : 'Unknown')}
          {shouldShowFullDetails && renderJobField('Job ID', job.jobId)}
          {(!shouldShowFullDetails) && renderJobField('Result Preview', job.resultPreview || 'N/A')}
          <div className="mt-2">
            <JobStatusBadge status={job.status} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobHistoryDetailsCard;