import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { getFountainLink } from "../../../lib/rssHelpers";
import CASCDRLogo from "../../../media/images/misc/cascdr-no-bg-just-waterfall.png";
import PlaceholderImage from "../../../media/images/misc/cascdr-placeholder.png";
import FountainLogo from "../../../media/images/misc/fountainLogo.png";
import "./EpisodeItemTableRow.css";

const EpisodeItemTableRow = ({ 
    episode, 
    isLoading, 
    onEpisodeSelect,
    showListenButton = false,
    showAnalyzeButton = false,
    className = ""
  }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isListenLoading, setIsListenLoading] = useState(false);
  
    useEffect(() => {
      const img = new Image();
      img.src = episode.episodeImage;
      img.onload = () => setIsImageLoaded(true);
    }, [episode.episodeImage]);
  
    const formatDate = (timestamp) => {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    };
  
    const handleListenClick = async (guid) => {
      setIsListenLoading(true);
      try {
        const fountainLink = await getFountainLink(guid);
        const finalLink = fountainLink ? fountainLink : guid;
        window.open(finalLink, '_blank');
      } catch (error) {
        console.error('Error opening Fountain link:', error);
      } finally {
        setIsListenLoading(false);
      }
    };
  
    return (
      <div className={`episode-item-table-row ${className}`}>
        <img
          src={isImageLoaded ? episode.episodeImage : PlaceholderImage}
          alt={episode.itemTitle}
          title={episode.itemTitle}
          className="episode-item-table-row-image"
        />
        <div className="episode-item-table-row-info">
          <div className="episode-item-table-row-details">
            <h3 className="episode-item-table-row-title">{episode.itemTitle}</h3>
            <p className="episode-item-table-row-creator">Creator: {episode.creator}</p>
            <p className="episode-item-table-row-date">Published: {formatDate(episode.publishedDate)}</p>
            <p className="episode-item-table-row-description">
              {episode.description?.length > 400
                ? `${episode.description.substring(0, 400)}...`
                : episode.description}
            </p>
          </div>
          <div className="episode-item-table-row-button-container">
            {/* Simple Select Button */}
            <button
              className="episode-item-table-row-select-button"
              onClick={() => onEpisodeSelect(episode.itemUrl, episode.episodeGUID)}
              disabled={isLoading}
            >
              {isLoading ? (
                <BeatLoader color="#fff" loading={true} size={8} />
              ) : (
                'Select'
              )}
            </button>
  
            {/* Optional Analyze Button */}
            {showAnalyzeButton && (
              <button
                className="episode-item-table-row-analyze-button"
                onClick={() => onEpisodeSelect(episode.itemUrl, episode.episodeGUID)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <BeatLoader color="#fff" loading={true} size={8} />
                ) : (
                  <>
                    <img src={CASCDRLogo} alt="Analyze" />
                    Analyze
                  </>
                )}
              </button>
            )}
  
            {/* Optional Listen Button */}
            {showListenButton && (
              <button
                className="episode-item-table-row-listen-button"
                onClick={() => handleListenClick(episode.episodeGUID)}
              >
                {isListenLoading ? (
                  <BeatLoader color="#333" loading={true} size={8} />
                ) : (
                  <>
                    <img src={FountainLogo} alt="Listen" />
                    Listen
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

export default EpisodeItemTableRow;
