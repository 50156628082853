import React from 'react';
import { JobStatus } from './jobHistoryManager';

const JobStatusBadge = ({ status }) => {
  let bgColor, textColor;
  switch (status.toUpperCase()) {
    case JobStatus.WORKING:
      bgColor = 'bg-yellow-100';
      textColor = 'text-yellow-800';
      break;
    case JobStatus.DONE:
      bgColor = 'bg-green-100';
      textColor = 'text-green-800';
      break;
    case JobStatus.ERROR:
      bgColor = 'bg-red-100';
      textColor = 'text-red-800';
      break;
    case JobStatus.AWAITING_PARENT_JOB:
      bgColor = 'bg-blue-100';
      textColor = 'text-blue-800';
      break;
    default:
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
  }
  
  return (
    <span style={{paddingTop:'8px',paddingBottom:'8px'}} className={`px-2 py-1 rounded-md text-xs font-medium ${bgColor} ${textColor}`}>
      {status.toUpperCase()}
    </span>
  );
};

export default JobStatusBadge;