import React, { createContext, useContext, useState, useEffect } from 'react';

const ActiveTabContext = createContext();

export const ActiveTabProvider = ({ children, initialActiveTab }) => {
  const [activeTab, setActiveTabState] = useState(initialActiveTab);
  const [tabHistory, setTabHistory] = useState([]);

  const setActiveTab = (newTab) => {
    if (activeTab !== null) {
      setTabHistory((prevHistory) => [...prevHistory, activeTab]);
    }
    setActiveTabState(newTab);
    updateURL(newTab);
  };

  const goBack = () => {
    setTabHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      const previousTab = newHistory.pop();

      if (previousTab) {
        setActiveTabState(previousTab);
        updateURL(previousTab);
      } else {
        // No tab history, fallback to browser back
        window.history.back();
      }

      return newHistory;
    });
  };

  const updateURL = (tab) => {
    const newUrl = new URL(window.location);
    if (tab) {
      newUrl.searchParams.set('tab', tab);
    } else {
      newUrl.searchParams.delete('tab');
    }
    window.history.pushState({ tab: tab }, '', newUrl);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      const tab = event.state?.tab || null;
      setActiveTabState(tab);
      if (!tab) {
        const newUrl = new URL(window.location);
        newUrl.searchParams.delete('tab');
        window.history.replaceState(null, '', newUrl);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab, goBack }}>
      {children}
    </ActiveTabContext.Provider>
  );
};


export function useActiveTab() {
  const context = useContext(ActiveTabContext);
  if (!context) {
    throw new Error('useActiveTab must be used within an ActiveTabProvider');
  }
  return context;
}
