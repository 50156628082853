import React, { useState, useEffect, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TranscriptAnalysisToggle from '../TranscriptAnalysisToggle';
import { button } from '@material-tailwind/react';

const CompartmentalizedTranscript = ({ 
  title, 
  transcriptData, 
  onRunAnalysis, 
  setCustomPrompt, 
  initialToggleTab, 
  showOptionsOnOpen = false, 
  isLoading = false,
  selectedViewAction,
  isFromContentSelection,
  onRunButtonClick
}) => {
  const [copiedSection, setCopiedSection] = useState(null);
  const [openSections, setOpenSections] = useState({ full: false, sentence: false, word: false });
  const [searchTerms, setSearchTerms] = useState({ full: '', sentence: '', word: '' });
  const [option, setOption] = useState('Summary');
  const [selectedView, setSelectedView] = useState(initialToggleTab);

  const transcript = transcriptData?.channels?.[0]?.alternatives?.[0];
  const buttonClass = "relative bg-gradient-to-r from-[#f8801e] to-[#f84c1e] text-white py-2 md:py-3 px-4 md:px-6 rounded-lg font-semibold text-base md:text-sm transition-all duration-100 ease-in-out border-2 border-orange-600 hover:border-white hover:border-2 focus:outline-none group"

  useEffect(() => {
    if (selectedViewAction && isFromContentSelection) {
      setOption(selectedViewAction);
      setSelectedView('AI Analysis');
    }
  }, [selectedViewAction, isFromContentSelection]);

  const handleCopy = useCallback((text, section) => {
    console.log(`handleCopy text:${text}`)
    console.log(`section:${section}`)
    navigator.clipboard.writeText(text).then(() => {
      setCopiedSection(section);
      setTimeout(() => setCopiedSection(null), 1000);
    });
  }, []);

  const toggleSection = useCallback((section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  }, []);

  const handleSearch = useCallback((section, term) => {
    setSearchTerms(prev => ({ ...prev, [section]: term }));
  }, []);

  const handleOptionChange = useCallback((newOption) => {
    console.log('Option changed:', newOption);
    setOption(newOption);
  }, []);

  const handlePromptChange = useCallback((newPrompt) => {
    console.log('prompt changed:', newPrompt);
    if (setCustomPrompt && typeof setCustomPrompt === 'function') {
      setCustomPrompt(newPrompt);
    }
  }, [setCustomPrompt]);

  const formatTime = useCallback((seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }, []);

  const getPreviewContent = useCallback((sectionName) => {
    if (!transcript) return '';
    if (sectionName === 'full') {
      return transcript.transcript.split('. ').slice(0, 3).join('. ') + '...';
    } else if (sectionName === 'sentence') {
      return transcript.paragraphs?.paragraphs?.slice(0, 2).map(p => p.sentences[0].text).join(' ') + '...';
    } else {
      return transcript.words.slice(0, 10).map(w => w.word).join(' ') + '...';
    }
  }, [transcript]);

  const renderTranscriptSection = useCallback((sectionName) => {
    if (!transcript) return null;
    const searchTerm = searchTerms[sectionName].toLowerCase();
    let filteredContent;

    if (sectionName === 'full') {
      filteredContent = transcript.transcript
        .split('. ')
        .filter(sentence => sentence.toLowerCase().includes(searchTerm));
    } else if (sectionName === 'sentence') {
      filteredContent = transcript.paragraphs?.paragraphs
        ?.flatMap(p => p.sentences)
        .filter(item => item.text.toLowerCase().includes(searchTerm)) || [];
    } else {
      filteredContent = transcript.words
        .filter(item => item.word.toLowerCase().includes(searchTerm));
    }    

    return (
      <div className="ctsm-transcript-section">
        <div className="ctsm-section-header" onClick={() => toggleSection(sectionName)}>
          <span className="ctsm-arrow">
            {openSections[sectionName] ? <ArrowDropDownIcon /> : <ArrowForwardIosIcon />}
          </span>
          <h2>{sectionName === 'full' ? 'Full Transcript' : sectionName === 'sentence' ? 'Sentence Level Timestamps' : 'Word Level Timestamps'}</h2>
          <button
            className={buttonClass}
            onClick={(e) => {
              e.stopPropagation();
              const sectionContent = sectionName === 'full'
                ? transcript.transcript
                : JSON.stringify(filteredContent.map(item => ({
                    start: item.start,
                    end: item.end,
                    text: item.text || item.punctuated_word || item.word
                  })), null, 2);
              handleCopy(sectionContent, sectionName);
            }}
          >
            {copiedSection === sectionName ? 'Copied!' : 'Copy'}
          </button>
        </div>
        {!openSections[sectionName] && sectionName === "full" && (
          <div className="ctsm-content">
                <ReactMarkdown className="ctsm-markdown-content">
                 {getPreviewContent(sectionName)}
                </ReactMarkdown>
            <button 
                className={buttonClass}
                onClick={() => toggleSection(sectionName)}
                style={{marginTop:'8px',marginBottom:'8px'}}
            >
                Show more
            </button>
          </div>
        )}
        {openSections[sectionName] && (
          <>
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchTerms[sectionName]}
              onChange={(e) => handleSearch(sectionName, e.target.value)}
              className="ctsm-search-input"
            />
            <div className="ctsm-content">
              {sectionName === 'full' ? (
                <ReactMarkdown className="ctsm-markdown-content">
                  {filteredContent.join('. ')}
                </ReactMarkdown>
              ) : sectionName === 'sentence' ? (
                filteredContent.map((item, index) => (
                  <p key={index} className="ctsm-timestamp-item">
                    <span className="ctsm-timestamp">{formatTime(item.start)}</span>
                    <span className="ctsm-text">{item.text}</span>
                  </p>
                ))
              ) : (
                filteredContent.map((item, index) => (
                  <p key={index} className="ctsm-timestamp-item">
                    <span className="ctsm-timestamp">{formatTime(item.start)}</span>
                    <span className="ctsm-text">{item.punctuated_word || item.word}</span>
                  </p>
                ))
              )}
            </div>
            <button 
            className={buttonClass} 
            onClick={() => toggleSection(sectionName)}
            style={{marginTop:'8px',marginBottom:'8px'}}
            >
                Show less
            </button>
          </>
        )}
      </div>
    );
  }, [transcript, searchTerms, openSections, buttonClass, handleCopy, toggleSection, handleSearch, formatTime, getPreviewContent, copiedSection]);

  const renderTranscriptContent = useCallback(() => (
    <>
      {renderTranscriptSection('full')}
      {renderTranscriptSection('sentence')}
      {renderTranscriptSection('word')}
    </>
  ), [renderTranscriptSection]);

  if (!transcript) {
    return <p>No transcript data available.</p>;
  }

  return (
    <div className="compartmentalized-transcript bg-gray-800 rounded-lg p-6 mb-4">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <TranscriptAnalysisToggle
        transcriptAvailable={!!transcript}
        renderTranscriptContent={renderTranscriptContent}
        onOptionChange={handleOptionChange}
        onCustomPromptChange={handlePromptChange}
        onRunAnalysis={onRunAnalysis}
        showOptionsOnOpen={showOptionsOnOpen}
        initialToggleTab={selectedView}
        isLoading={isLoading}
        selectedViewAction={selectedViewAction}
        option={option}
        setOption={setOption}
        onRunButtonClick={onRunButtonClick}
      />
    </div>
  );
};

export default React.memo(CompartmentalizedTranscript);