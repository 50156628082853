import React, { useState, useEffect, useCallback } from 'react';
import PromptOptionsComponent from './PromptOptionsComponent';
import './PromptOptionsComponent.css';
import { BeatLoader } from 'react-spinners';
import { defaultRSSPrompts } from './nip105-client';

const TranscriptAnalysisToggle = ({ 
  transcriptAvailable, 
  renderTranscriptContent, 
  onOptionChange,
  onCustomPromptChange,
  onRunAnalysis,
  showOptionsOnOpen = false,
  initialToggleTab,
  isLoading,
  selectedViewAction,
  option,
  setOption,
  onRunButtonClick
}) => {
  const [selectedView, setSelectedView] = useState(initialToggleTab);
  const [customPrompt, setCustomPrompt] = useState('');
  const buttonClass = "relative bg-gradient-to-r from-[#f8801e] to-[#f84c1e] text-white py-2 md:py-3 px-4 md:px-6 rounded-lg font-semibold text-base md:text-sm transition-all duration-100 ease-in-out border-2 border-orange-600 hover:border-white hover:border-2 focus:outline-none group"

  useEffect(() => {
    setSelectedView(initialToggleTab);
  }, [initialToggleTab]);

  useEffect(() => {
    if (selectedViewAction) {
      setSelectedView('AI Analysis');
      setOption(selectedViewAction);
    }
  }, [selectedViewAction, setOption]);

  const handleViewChange = useCallback((view) => {
    setSelectedView(view);
  }, []);

  const handleRunAnalysis = useCallback(() => {
    if(onRunAnalysis) {
      onRunAnalysis();
    }
    if(onRunButtonClick) {
      console.log(`TranscriptAnalysisToggle handleRunAnalysis option:`, option);
      console.log(`TranscriptAnalysisToggle handleRunAnalysis customPrompt:`, customPrompt);
      onRunButtonClick(option, customPrompt);
    }

    setSelectedView('AI Analysis');
  }, [onRunAnalysis, onRunButtonClick, option, customPrompt]);

  const handleCustomPromptChange = useCallback((newPrompt) => {
    setCustomPrompt(newPrompt);
    if (onCustomPromptChange) {
      onCustomPromptChange(newPrompt);
    }
  }, [onCustomPromptChange]);

  const handleOptionChange = useCallback((newOption) => {
    console.log(`handleOptionChange:`, newOption);
    setOption(newOption);
    setCustomPrompt(defaultRSSPrompts[newOption] || '');
    console.log(`defaultRSSPrompts[newOption]:`, defaultRSSPrompts[newOption]);
  }, [setOption, setCustomPrompt]);

  return (
    <div className="transcript-analysis-toggle">
      <div className="segmented-control">
        <button
          className={`segmented-control-button ${selectedView === 'Transcript' ? 'active' : ''}`}
          onClick={() => handleViewChange('Transcript')}
          disabled={!transcriptAvailable}
        >
          Transcript
        </button>
        <button
          className={`segmented-control-button ${selectedView === 'AI Analysis' ? 'active' : ''}`}
          onClick={() => handleViewChange('AI Analysis')}
        >
          AI Analysis
        </button>
      </div>

      <div className="content-area">
        {selectedView === 'Transcript' && transcriptAvailable ? (
          renderTranscriptContent()
        ) : (
          <div className="ai-analysis-container">
            <PromptOptionsComponent
              initialOption={option}
              onOptionChange={handleOptionChange}
              onCustomPromptChange={handleCustomPromptChange}
              showOptionsOnOpen={showOptionsOnOpen}
            />
            <div className="ctsm-run-button-container">
              <button className={buttonClass} onClick={handleRunAnalysis}>
                {isLoading ? (
                  <BeatLoader color="white" size={8} loading={true} />
                ) : (
                  <strong>Run</strong>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TranscriptAnalysisToggle);