import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useActiveTab } from '../ActiveTabContext';
import "./CarouselComponent.css"

// Import images (assuming these imports work correctly)
import jamieLogo from '../../media/images/services/jamie-logo-final.png';
import transcriberLogo from '../../media/images/services/rss-transcript-v2.png';
import rssAgentLogo from '../../media/images/services/rss-agent-v2.png';

import leftArrowImage from '../../media/images/misc/back-button.png';
import rightArrowImage from '../../media/images/misc/forward-button.png';

const slidesData = [
  {
    title: 'JAMIE MEDIA ASSISTANT',
    description: 'Transcribe media & instantly create content with AI',
    image: jamieLogo,
    background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
    actionId: 'jamie',
  },
  {
    title: 'GET A PODCAST TRANSCRIPT',
    description: 'Pull a Podcast & Transcribe with Whisper in one click.',
    image: transcriberLogo,
    background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
    actionId: 'Logic%20App&wfid=yitter_to_transcriptions',
  },
  {
    title: 'RSS AGENT',
    description: 'Write summaries and articles from RSS feeds on demand.',
    image: rssAgentLogo,
    background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
    actionId: 'yt-agent',
  },
];

const CustomArrow = ({ direction, onClick }) => (
  <button
    onClick={onClick}
    className={`absolute ${direction === 'left' ? 'left-[30px]' : 'right-[30px]'} top-1/2 transform -translate-y-1/2 w-[70px] h-[70px] z-10 p-2 border-none bg-transparent flex items-center justify-center rounded-full transition-all duration-00 ease-in-out hover:outline hover:outline-2 hover:outline-white hover:outline-offset-0 hover:shadow-[0_0_0_1px_white]`}
  >
    <img
      src={direction === 'left' ? leftArrowImage : rightArrowImage}
      alt={direction === 'left' ? "Previous" : "Next"}
      className="w-full h-full"
    />
  </button>
);

const CarouselComponent = ({title}) => {
  const { activeTab, setActiveTab } = useActiveTab();
  const sliderRef = React.useRef(null);

  const rootUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    dotsClass: "slick-dots custom-dots", // Add a custom class for additional styling if needed
  };

  const handlePopState = () => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    const wfid = params.get('wfid')
    if (tab) {
      setActiveTab(tab);
    }
  };

  const handleSlideClick = (actionId) => {
    const newUrl = `${rootUrl}/?tab=${actionId}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    handlePopState();
  };

  return (
    <div className="w-full max-w-4xl mx-auto relative">
      <h2 className="text-3xl font-bold text-white mb-8 text-left font-['League_Spartan'] px-6">{title}</h2>
      <div className="relative cursor-pointer">
        <Slider {...settings} ref={sliderRef}>
          {slidesData.map((slide, index) => (
            <div key={index} className="px-6">
              <div className="relative transition-all duration-100 hover:border-4 hover:border-white hover:shadow-[0_0_15px_5px_rgba(255,255,255,0.8)] rounded-lg">
                <div 
                  className={`w-full h-80 rounded-lg flex flex-col justify-center items-center p-6 ${slide.background}`}
                  onClick={() => handleSlideClick(slide.actionId)}
                >
                  <img
                    src={slide.image}
                    alt={slide.title}
                    className="w-28 h-28 mb-4 md:w-28 md:h-28 w-[90px] h-[90px]"
                  />
                  <h2 className="text-2xl md:text-2xl text-xl font-bold text-black mb-1 text-center ml-6 mr-6">
                    {slide.title}
                  </h2>
                  <p 
                    className="text-black text-center mx-auto leading-tight tracking-wide md:text-2xl text-lg"
                    style={{
                      fontFamily: 'League Spartan, sans-serif',
                      fontWeight: '400',
                      maxWidth: '80%',
                      paddingLeft:'32px',
                      paddingRight:'32px'
                    }}
                  >
                    {slide.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
          <CustomArrow direction="left" onClick={() => sliderRef.current.slickPrev()} />
        </div>
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
          <CustomArrow direction="right" onClick={() => sliderRef.current.slickNext()} />
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
