import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import JobHistoryDetailsCard from './JobHistoryDetailsCard';
import CompartmentalizedTranscript from './CompartmentalizedTranscript';
import ContentSelectionButtons from './ContentSelectionButtons';
import MarkdownContentView from './MarkdownContentView';
import BubbleAlert from './modal/BubbleAlert';
import { getJob, JobStatus, addBlockedJob, triggerAnalysisJob, getJobsByParentJobId, setupPeriodicRefresh, cancelPeriodicRefresh } from './jobHistoryManager';
import { contentTypes } from '../nip105-client';
import { Cog } from 'lucide-react';

const JobHistoryDetailsComponent = ({ jobId, onBack, onRunAnalysis, setCustomPrompt }) => {
    const [job, setJob] = useState(null);
    const [contentData, setContentData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [isFromContentSelection, setIsFromContentSelection] = useState(false);
    const [bubbleMessage, setBubbleMessage] = useState('');
    const [showBubble, setShowBubble] = useState(false);
    const refreshIntervalRef = useRef(null);
    const ContentType = {
        TRANSCRIPT: 'Transcript',
        ANALYSIS: 'Analysis',
        UNKNOWN: 'Unknown'
    };

    const showBubbleAlert = (message) => {
        setBubbleMessage(message);
        setShowBubble(true);
        setTimeout(() => setShowBubble(false), 4000);
    }

    const fetchJobData = useCallback(() => {
        const jobData = getJob(jobId);
        setJob(jobData);
    }, [jobId]);

    const fetchContent = useCallback(async (contentJobId) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`https://whispr-v3-w-caching-ex8zk.ondigitalocean.app/WHSPR/${contentJobId}/get_result`);
            setContentData(response.data);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to load data. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const processRelevantJobChanges = useCallback(() => {
        console.log('Processing relevant job changes...');
        const parentJobId = jobId.startsWith('T-') ? jobId.split('-')[1] : jobId;
        const relevantJobs = getJobsByParentJobId(parentJobId).filter(job => job.status !== JobStatus.AWAITING_PARENT_JOB);
        if(relevantJobs.length > 0) {
            console.log(`relevantJobs: ${JSON.stringify(relevantJobs, null, 2)}`);
            const latestJob = relevantJobs[0];
            setJob(prevJob => {
                if (JSON.stringify(prevJob) !== JSON.stringify(latestJob)) {
                    console.log('Updating job state:', latestJob);
                    return latestJob;
                }
                return prevJob;
            });
            if (latestJob.status === JobStatus.DONE) {
                console.log('Job is done, cancelling polling');
                fetchContent(latestJob.jobId);
                if (refreshIntervalRef.current) {
                    cancelPeriodicRefresh(refreshIntervalRef.current);
                    refreshIntervalRef.current = null;
                }
                setIsLoading(false);
            }
        }
    }, [jobId, fetchContent]);

    const pollParentJob = useCallback(() => {
        console.log('Starting to poll parent job...');
        setIsLoading(true);
        setTimeout(() => {
            if (!refreshIntervalRef.current) {
                refreshIntervalRef.current = setupPeriodicRefresh(() => {
                    processRelevantJobChanges();
                }, 5000); // Poll every 5 seconds
            }
        }, 2000); // 2-second delay before starting to poll
    }, [processRelevantJobChanges]);

    const handleRunButtonClick = useCallback(async (selectedOption, customPrompt) => {
        console.log(`JobHistoryDetailsComponent handleRunButtonClick: ${selectedOption} ${customPrompt}`);
        const displayedOption = contentTypes.find(option => option.id === selectedOption)?.label || selectedOption;
        const serviceName = 'Jamie';
        const inputField1 = displayedOption;
        const inputField2 = customPrompt;
        const parentJobId = jobId; // Use the current job ID as the parent
        const outputType = displayedOption;

        // Add blocked job
        const newJob = addBlockedJob(serviceName, inputField1, inputField2, parentJobId, outputType, 'Waiting for analysis to start');

        showBubbleAlert('Analysis job started!');
        // Immediately trigger the analysis job
        await triggerAnalysisJob(newJob, parentJobId);

        // Optionally, you can update the UI or trigger a refresh here
    }, [jobId]);

    useEffect(() => {
        fetchJobData();
        if (jobId.startsWith('T-')) {
            pollParentJob();
        } else {
            fetchContent(jobId);
        }

        return () => {
            if (refreshIntervalRef.current) {
                cancelPeriodicRefresh(refreshIntervalRef.current);
                refreshIntervalRef.current = null;
            }
        };
    }, [fetchJobData, fetchContent, jobId, pollParentJob]);

    useEffect(() => {
        if (job && getContentType() !== ContentType.UNKNOWN && job.status === JobStatus.WORKING) {
            setJob(prevJob => ({ ...prevJob, status: JobStatus.DONE }));
        }
    }, [contentData, job]);

    const handleActionSelect = useCallback((action) => {
        setSelectedAction(action.id);
        setIsFromContentSelection(true);
        console.log('Selected action:', action);
    }, []);

    const handleCustomPromptChange = useCallback((newPrompt) => {
        if (setCustomPrompt && typeof setCustomPrompt === 'function') {
            setCustomPrompt(newPrompt);
        }
    }, [setCustomPrompt]);

    const getContentType = useCallback(() => {
        if(contentData && contentData.transcript){
            return ContentType.ANALYSIS;
        }
        else if(contentData && contentData.channels){
            return ContentType.TRANSCRIPT;
        }
        else{
            return ContentType.UNKNOWN;
        }
    }, [contentData]);

    const isTranscript = job?.inputField1 === "Transcript from Link" || job?.inputField1 === "Transcript from Upload";
    const isAnalysisJob = ["Summary", "Show Notes", "YouTube Description", "Article", "Quotes"].includes(job?.inputField1);

    const renderContent = useCallback(() => {
        if (!contentData || !job) return null;
        const type = getContentType();
        switch(type){
            case ContentType.ANALYSIS:
                return (
                    <MarkdownContentView
                        title={`${job.inputField1} Results:`}
                        content={contentData.transcript}
                        onCopy={(text) => navigator.clipboard.writeText(text)}
                    />
                );
            case ContentType.TRANSCRIPT:
                return (
                    <CompartmentalizedTranscript
                        title={`${job.inputField1} Results:`}
                        transcriptData={contentData}
                        onRunAnalysis={onRunAnalysis}
                        setCustomPrompt={handleCustomPromptChange}
                        initialToggleTab="Transcript"
                        showOptionsOnOpen={true}
                        selectedViewAction={selectedAction}
                        isFromContentSelection={isFromContentSelection}
                        onRunButtonClick={handleRunButtonClick}
                    />
                );
            default:
                return <p>No content available for this job type.</p>;
        }
    }, [contentData, job, getContentType, onRunAnalysis, handleCustomPromptChange, selectedAction, isFromContentSelection, handleRunButtonClick]);

    if (!job) {
        return <div>Loading job details...</div>;
    }

    return (
        <div className="job-details-container bg-black text-white p-6">
            <div className="max-w-7xl mx-auto" style={{marginTop:'125px'}}>
                <button 
                    onClick={onBack}
                    className="text-xl font-bold mb-6 hover:text-orange-200"
                >
                    ← Back to Job History
                </button>
                <div className={`grid ${isTranscript ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1'} gap-6`}>
                    <div>
                        <JobHistoryDetailsCard job={job} isCompact={true} shouldShowFullDetails={true}/>
                    </div>
                    {isTranscript && (
                        <div>
                            <ContentSelectionButtons 
                                title={"Analysis Tools Shortcuts"} 
                                onActionSelect={handleActionSelect}
                            />
                        </div>
                    )}
                </div>
                <div className="mt-6">
                    {showBubble && <BubbleAlert message={bubbleMessage} />}
                    {isLoading && 
                    (<div className="flex flex-col items-center justify-center mt-20 bg-gray-800" style={{borderRadius:'16px', paddingTop:'24px',paddingBottom:'24px'}}>
                        <Cog size={64} className="text-white-500 mb-4" />
                        <p className="text-xl text-white-500">{job.status === JobStatus.AWAITING_PARENT_JOB ? "Awaiting parent job to complete!" : "Your Job is Running!"}</p>
                        <p className="text-white-400 mt-2">Wait here or come back later to see the results</p>
                        <div className="flex justify-center mt-2" role="status">
                            <svg aria-hidden="true" className="w-16 h-16 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#4B5563"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="white"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    )}
                    {error && <p className="text-red-500">{error}</p>}
                    {!isLoading && !error && renderContent()}
                </div>
            </div>
        </div>
    );
};

export default JobHistoryDetailsComponent;