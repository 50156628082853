import React from 'react';
import styled from 'styled-components';

const BubbleWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  max-width: 300px;
  background-color: ${props => props.backgroundColor || 'black'};
  color: ${props => props.textColor || 'white'};
  text-align: center;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeinout 4s;
  border: 2px solid ${props => props.borderColor || 'white'};
  font-weight: 600;

  @keyframes fadeinout {
    0%, 100% { opacity: 0; }
    20%, 80% { opacity: 1; }
  }
`;

const BubbleAlert = ({ message, backgroundColor, textColor, borderColor }) => {
  return (
    <BubbleWrapper 
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor={borderColor}
    >
      {message}
    </BubbleWrapper>
  );
};

export default BubbleAlert;